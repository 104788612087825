<template>
  <div style="background-color: #ffffff" class="py-8" v-if="watchList.length > 0">
    <v-container>
      <v-row>
        <v-col
          ><div class="heading">
            WizFit Video Progress by
            {{ getCampaign.student_detail.student_first_name }}.
            <div class="hLine"></div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="4"
          sm="2"
          v-for="(item, i) in watchList"
          :key="i"
          class="py-4"
        >
          <img :src="`${item.thumbnail}`" width="80%" alt="" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { mapGetters } from "vuex";
import { API_GET_STUDENT_VIDEO_PROGRESS } from "@/constants/APIUrls";
export default {
  name: "WizfitVideoProgress",
  data() {
    return {
      watchList: [],
      // videoData: [
      //   { src: "wellness.png", srcActive: "wellnessActive.png" },
      //   { src: "imagination.png", srcActive: "imaginationActive.png" },
      //   { src: "zeroHate.png", srcActive: "zeroHateActive.png" },
      //   { src: "fun.png", srcActive: "funActive.png" },
      //   { src: "inspiration.png", srcActive: "inspirationActive.png" },
      //   { src: "teamWork.png", srcActive: "temaWorkActive.png" },
      // ],
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getStudentId() {
      return this.getCampaign.type.student_id;
    },
  },
  watch: {},
  methods: {
    getVideoProgress() {
      const successHandler = (res) => {
        this.watchList = res.data.video_watch_list || [];
        console.log("wizfitvideo",res);

      };
      const failureHandler = (res) => {
        console.log("wizfitvideo",res);
      };
      let formData = {};
      formData["student_id"] = this.getStudentId;
      Axios.request_GET(
        API_GET_STUDENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getImgUrl(src) {
      return require("@/assets/publicDonationPage/wizfitVideoProgress/" + src);
    },
    getActiveInactiveImgSrc() {},
  },
  mounted() {
    this.getVideoProgress();
  },
};
</script>
<style scoped>
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    margin-top: 15px;
    float: right;
  }
}
</style>
